import ProductList from './components/product-list';

export default function ProductHub() {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="hidden md:flex h-full md:max-h-[0px] lg:max-h-[30px]" />
      <ProductList />
      <div className="hidden md:flex h-full md:max-h-[50px] lg:max-h-[80px]" />
    </div>
  );
}
