import { AppComponent } from '@fluencyacademy/core-components-web';

import usePlatforms from '../hooks/use-platforms';
import { useProductList } from '../hooks/use-product-list';
import { HomeProduct, HomeProductItem } from '../types/product';
import AppearDiv from './appear-div';
import CollapseItem from './collapse-item';
import ProductCoursesContent from './product-courses-content';
import ProductCoursesTitle from './product-courses-title';
import ProductMemorizationContent from './product-memorization-content';
import ProductMemorizationTitle from './product-memorization-title';
import ProductTalksContent from './product-talks-content';
import ProductTalksTitle from './product-talks-title';

const PRODUCTS: Array<HomeProductItem> = [
  {
    product: HomeProduct.COURSES,
    title: <ProductCoursesTitle />,
    content: <ProductCoursesContent />,
  },
  {
    product: HomeProduct.MEMORIZATION,
    title: <ProductMemorizationTitle />,
    content: <ProductMemorizationContent />,
  },
  {
    product: HomeProduct.TALKS,
    title: <ProductTalksTitle />,
    content: <ProductTalksContent />,
  },
];

const ProductList: AppComponent = () => {
  usePlatforms();
  const { selectedProduct, setSelectedProduct } = useProductList();

  return (
    <AppearDiv delay={0.3} className="flex h-full w-full justify-center items-center px-5 md:px-[24px] lg:px-[64px]">
      <div className="flex flex-col md:flex-row h-full w-full gap-5 md:gap-7 lg:max-w-[1780px] justify-end md:justify-center pt-[20px] pb-[60px] md:py-0">
        {PRODUCTS.map((e, index) => (
          <CollapseItem
            key={index}
            product={e.product}
            title={e.title}
            content={e.content}
            isSelected={selectedProduct === index}
            onChangeSelected={() => setSelectedProduct(index)}
          />
        ))}
      </div>
    </AppearDiv>
  );
};

export default ProductList;
